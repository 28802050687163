import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import caretL from '../../svg/caret-l.svg'
import caretR from '../../svg/caret-r.svg'
import Carousel from 'react-slick'
import '../slick.css'
import '../slick-theme.css'
import * as styles from './movie.module.css'

// https://react-slick.neostack.com/docs/example/custom-arrows
function NextArrow(props) {
  const { className, onClick } = props
  return (
    <div className={className} onClick={onClick}>
      <a href="#" title="Next">
        <span
          className="caret"
          style={{
            backgroundImage: `url(${caretR})`,
          }}
        ></span>
      </a>
    </div>
  )
}

function PrevArrow(props) {
  const { className, onClick } = props
  return (
    <div className={className} onClick={onClick}>
      <a href="#" title="Previous">
        <span
          className="caret"
          style={{
            backgroundImage: `url(${caretL})`,
          }}
        ></span>
      </a>
    </div>
  )
}

const carouselSettings = {
  dots: false,
  infinite: true,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
  slidesToScroll: 1,
  slidesToShow: 1,
  speed: 500,
}

export default ({ blurb, movieImages, sectionCount }) => (
  <section className="pull-right bkd-jade">
    <div className={styles.movie}>
      <div className={styles.movieTextWrap}>
        <header>
          <p className="section-number">0{(sectionCount += 1)}</p>
          <h2 id="movie">Movie</h2>
        </header>
        <div className="mt">
          <div
            className="quote-text"
            dangerouslySetInnerHTML={{
              __html: blurb.childMarkdownRemark.html,
            }}
          />
        </div>
      </div>
      <div className={styles.movieImageWrap}>
        <Carousel {...carouselSettings}>
          {movieImages.map((movieImage) => {
            return (
              <div key={movieImage.id} className={styles.carouselSlide}>
                <div>
                  {/* className={styles.carouselSlideInner} */}
                  <GatsbyImage
                    image={movieImage.gatsbyImageData}
                    className={styles.heroImage}
                    alt=""
                  />
                </div>
              </div>
            )
          })}
        </Carousel>
      </div>
    </div>
  </section>
)
