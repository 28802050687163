import React from 'react'
import { Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import * as styles from './edition-preview.module.css'

export default ({ bookTitle, edition }) => (
  <>
    <div className={styles.editionImageWrapper}>
      {edition.coverImage && (
        <GatsbyImage image={edition.coverImage.gatsbyImageData} alt="" />
      )}
    </div>
    <div className={styles.editionDetails}>
      <h3 className={styles.editionName}>{bookTitle}</h3>
      <h5 className="accent">{edition.editionName}</h5>

      <div
        className={styles.editionDescription}
        dangerouslySetInnerHTML={{
          __html: edition.description.childMarkdownRemark.html,
        }}
      />
      <a className="button mobile-full butt-pad" href={edition.amazon}>
        Amazon
      </a>
      <a className="button mobile-full butt-pad" href={edition.barnesAndNoble}>
        B &amp; N
      </a>
      <a className="button mobile-full butt-pad" href={edition.harperCollins}>
        Harper Collins
      </a>
      <a className="button mobile-full butt-pad" href={edition.indieBound}>
        Indie Bound
      </a>
    </div>
  </>
)
