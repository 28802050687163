import React from 'react'
import EditionPreview from './edition-preview'
import * as styles from './editions.module.css'

export default ({ bookTitle, editions, sectionCount }) => (
  <section>
    <header>
      <p className="section-number">0{sectionCount}</p>
      <h2 id="editions">Editions</h2>
    </header>
    <div className={styles.editionList}>
      {editions.map((edition) => {
        return (
          <article key={edition.id} className={styles.editionListItem}>
            <EditionPreview bookTitle={bookTitle} edition={edition} />
          </article>
        )
      })}
    </div>
  </section>
)
