import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import caretL from '../svg/caret-l.svg'
import caretR from '../svg/caret-r.svg'
import Carousel from 'react-slick'
import './slick.css'
import './slick-theme.css'
import * as styles from './reviews.module.css'

// https://react-slick.neostack.com/docs/example/custom-arrows
function NextArrow(props) {
  const { className, onClick } = props
  return (
    <div className={className} onClick={onClick}>
      <a href="#" title="Next">
        <span
          className="caret"
          style={{
            backgroundImage: `url(${caretR})`,
          }}
        ></span>
      </a>
    </div>
  )
}

function PrevArrow(props) {
  const { className, onClick } = props
  return (
    <div className={className} onClick={onClick}>
      <a href="#" title="Previous">
        <span
          className="caret"
          style={{
            backgroundImage: `url(${caretL})`,
          }}
        ></span>
      </a>
    </div>
  )
}

const carouselSettings = {
  dots: false,
  infinite: true,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
  slidesToScroll: 1,
  slidesToShow: 1,
  speed: 500,
}

export default ({ reviews, reviewImage, sectionCount }) => (
  <section className="pull-left bkd-jade">
    <div className={styles.review}>
      <div className={styles.reviewImageWrap}>
        <GatsbyImage image={reviewImage} className={styles.heroImage} alt="" />
      </div>
      <div className={styles.reviewTextWrap}>
        <header className="pad-left-1ch">
          <p className="section-number">0{sectionCount}</p>
          <h2 id="reviews">Reviews</h2>
        </header>
        <div className="mt">
          <Carousel {...carouselSettings}>
            {reviews.map((review) => {
              return (
                <div key={review.id}>
                  <div className="pad-left-1ch">
                    <div
                      key={review.id}
                      className="inline quote-wrap review-text"
                      dangerouslySetInnerHTML={{
                        __html: review.review.childMarkdownRemark.html,
                      }}
                    />
                    <p className="no-mb">{review.reviewer}</p>
                  </div>
                </div>
              )
            })}
          </Carousel>
        </div>
      </div>
    </div>
  </section>
)
