import React from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import { GatsbyImage } from 'gatsby-plugin-image'
import Layout from '../components/layout'
import Purchase from '../components/purchase'
import Awards from '../components/awards/awards'
import Reviews from '../components/reviews'
import Editions from '../components/editions/editions'
import Movie from '../components/movie/movie'
import * as styles from './book.module.css'

class BookTemplate extends React.Component {
  render() {
    const book = this.props.data.contentfulBook
    let sectionCount = 0
    return (
      <Layout location={this.props.location}>
        <div>
          <Helmet title={book.title} />
          <section className="top">
            <div className={styles.hero}>
              <header>
                <h1 className="hero-title reduce">{book.title}</h1>
                <div
                  dangerouslySetInnerHTML={{
                    __html: book.blurb.childMarkdownRemark.html,
                  }}
                ></div>
              </header>
              <div className={styles.heroImageWrap}>
                <GatsbyImage
                  image={book.heroImage.gatsbyImageData}
                  className={styles.heroImage}
                  alt={book.title}
                />
              </div>
            </div>
          </section>
          <Purchase
            amazon={book.amazon}
            barnesAndNoble={book.barnesAndNoble}
            googlePlay={book.googlePlay}
            iBooks={book.iBooks}
            indieBound={book.indieBound}
            indigo={book.indigo}
          />
          <section>
            <header>
              <p className="section-number">0{(sectionCount += 1)}</p>
              <h2 id="summary">Summary</h2>
            </header>
            <div
              className="section-description mt"
              dangerouslySetInnerHTML={{
                __html: book.summary.childMarkdownRemark.html,
              }}
            />
          </section>
          {book.awards && <Awards awards={book.awards} />}
          {book.reviews && book.reviewsImage && (
            <Reviews
              reviews={book.reviews}
              reviewImage={book.reviewsImage.gatsbyImageData}
              sectionCount={
                (sectionCount += book.reviews && book.reviewsImage ? 1 : 0)
              }
            />
          )}
          <section className="bkd-ash">
            <header>
              <p className="section-number">0{(sectionCount += 1)}</p>
              <h2 id="ordering-facts">Ordering Facts</h2>
            </header>
            <div className="section-description mt flex wrap order-facts">
              <div className="split-column first">
                {book.signedCopy && (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: book.signedCopy.childMarkdownRemark.html,
                    }}
                  />
                )}
                {book.signedCopyLink && (
                  <a
                    href={book.signedCopyLink}
                    className="button mt mobile-full"
                  >
                    Order Copy
                  </a>
                )}
                <p className="mt3d">
                  {book.isbn10 && (
                    <span>
                      <strong>ISBN-10:</strong> {book.isbn10}
                      <br />
                    </span>
                  )}
                  {book.isbn13 && (
                    <span>
                      <strong>ISBN-13:</strong> {book.isbn13}
                      <br />
                    </span>
                  )}
                  {book.publishDate} by {book.publisher}
                </p>
              </div>
              <div className="split-column">
                {book.territories && (
                  <>
                    <span>Also available in the following territories:</span>
                    <ul className="territory-list">
                      {book.territories.map((territory) => {
                        return (
                          <li key={territory.id}>
                            <strong>{territory.territory}:</strong>{' '}
                            {territory.publisherLink ? (
                              <a href={territory.publisherLink}>
                                {territory.publisher}
                              </a>
                            ) : (
                              territory.publisher
                            )}
                          </li>
                        )
                      })}
                    </ul>
                  </>
                )}
              </div>
            </div>
          </section>
          {book.editions && (
            <Editions
              bookTitle={book.title}
              editions={book.editions}
              sectionCount={(sectionCount += book.editions ? 1 : 0)}
            />
          )}
          {book.movieBlurb && book.movieImages && (
            <Movie
              blurb={book.movieBlurb}
              movieImages={book.movieImages}
              sectionCount={sectionCount}
            />
          )}
        </div>
      </Layout>
    )
  }
}

export default BookTemplate

export const pageQuery = graphql`
  query BookBySlug($slug: String!) {
    contentfulBook(slug: { eq: $slug }) {
      amazon
      awards {
        awardName
        category
        id
        year
      }
      barnesAndNoble
      blurb {
        childMarkdownRemark {
          html
        }
      }
      editions {
        amazon
        barnesAndNoble
        coverImage {
          gatsbyImageData(height: 384, width: 256, layout: CONSTRAINED)
        }
        description {
          childMarkdownRemark {
            html
          }
        }
        harperCollins
        editionName
        indieBound
        id
      }
      googlePlay
      heroImage {
        gatsbyImageData(width: 600, layout: CONSTRAINED)
      }
      iBooks
      indieBound
      indigo
      isbn10
      isbn13
      movieBlurb {
        childMarkdownRemark {
          html
        }
      }
      movieImages {
        gatsbyImageData(height: 956, width: 956, layout: CONSTRAINED)
        id
      }
      publishDate(formatString: "MMMM Do, YYYY")
      publisher
      reviews {
        id
        reviewer
        review {
          childMarkdownRemark {
            html
          }
        }
      }
      reviewsImage {
        gatsbyImageData(width: 969, layout: CONSTRAINED)
      }
      signedCopy {
        childMarkdownRemark {
          html
        }
      }
      signedCopyLink
      summary {
        childMarkdownRemark {
          html
        }
      }
      territories {
        id
        territory
        publisher
        publisherLink
      }
      title
    }
  }
`
